import { BoxGeometry } from 'three';
import requestsManager from '../../api-requests';

const debugCubeModel = () => {
  const dim = 50;
  const geometry = new BoxGeometry(dim, dim, dim);
  geometry.computeBoundingBox();
  return { objects: { lower_jaw: geometry } };
};

export const getModel = () => {
  const modelUrl = requestsManager.getModelUrl();
  if (modelUrl === 'cube') {
    debugCubeModel();
  }
  return requestsManager.getItrModel();
};

export const getCompareModel = (orderId) => {
  return requestsManager.getCompareItrModel(orderId);
};

import { Assets } from '@itero/assets-bff-sdk';
import { utils } from '../utils';
import logger from '../logger';

const pollForAssetCreation = async (getAssetFunc) => {
  const MAX_ATTEMPTS = 15;
  const POLLING_INTERVAL = 3000;

  for (let i = 0; i < MAX_ATTEMPTS; i++) {
    try {
      return await getAssetFunc();
    } catch (error) {
      logger
        .error(`pollForAssetCreation: error: ${error}`)
        .data({ module: 'itr-fetcher' })
        .to(['host'])
        .end();
      await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL));
    }
  }
};

const getAssetSdk = () => {
  if (!utils) {
    return null;
  }
  const assetManagerUrl = utils.getAssetManagerUrl();
  if (!assetManagerUrl) {
    return null;
  }

  return new Assets(assetManagerUrl);
};

export const assetManagerUtils = {
  getAssetSdk,
  pollForAssetCreation,
};

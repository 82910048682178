import React, { useEffect, useState } from 'react';
import { utils, eventBus, globalEventsKeys, cacheKeys, cacheManager } from '@web-3d-tool/shared-logic';
import styles from './Splitter.module.css';
import styles360 from './Splitter360.module.css';
import { getWidth, isCurrentModelAliasAllowed, getCurrentModelAlias } from './Splitter.logic';

const handleOnChangeClick = () => {
  eventBus.raiseEvent(globalEventsKeys.TOGGLE_CHANGE_SCAN_BUTTON);
};

export const getClassName = (is360, isAOHS, propsMatrixLength) => {
  if (is360) {
    return !isAOHS && propsMatrixLength > 1 ? styles360.containerWithSplit : styles360.container;
  }
  return styles.container;
};

export const shouldRenderChangeButton = (isModelCompareActive, getPatientOrdersForComparison, compareRowIndex, j) => {
  return isModelCompareActive && getPatientOrdersForComparison.length > 2 && compareRowIndex === j;
};

const Splitter = (props) => {
  const {
    renderComp,
    propsMatrix,
    isModelCompareActive,
    imageFrameDimentions = { width: window.innerWidth / 4 + 32, drawerWidth: 32 },
    isSplittedViewWithSidePluginActive,
    translationsForSplitter,
    isOnLanding,
    compareRowIndex,
  } = props;

  const { getIs360HubEnabled, isAOHSEnv } = utils;
  const is360 = getIs360HubEnabled();
  const isAOHS = isAOHSEnv();
  const margin = '0 auto';
  const isGalleryViewActive = propsMatrix.flat().length === 5;

  const [, setWindowSizeReset] = useState({});
  const { getPatientOrdersForComparison } = cacheManager.get(cacheKeys.APP_SETTINGS) || {};

  useEffect(() => {
    const windowResize = () => {
      setWindowSizeReset({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', windowResize);
  }, []);

  return (
    <div className={getClassName(is360, isAOHS, propsMatrix.length)} id="splitterDiv">
      {propsMatrix.map((propsArr, i) => (
        <div
          key={i}
          className={is360 ? styles360.splitterRow : styles.splitterRow}
          style={{ height: `${100 / propsMatrix.length}%` }}
        >
          {propsArr.map((componentProps, j) => (
            <div
              style={{
                width: getWidth(propsArr, is360, isSplittedViewWithSidePluginActive, imageFrameDimentions),
                height: '100%',
                ...(isOnLanding ? { margin: margin } : {}),
              }}
              key={j}
            >
              {renderComp(componentProps, i, j)}
              {isCurrentModelAliasAllowed(propsArr.length, isModelCompareActive, isGalleryViewActive) && (
                <div
                  className={styles360.splitterRowSign}
                  onClick={
                    shouldRenderChangeButton(isModelCompareActive, getPatientOrdersForComparison, compareRowIndex, j)
                      ? handleOnChangeClick
                      : null
                  }
                  style={{
                    cursor: shouldRenderChangeButton(
                      isModelCompareActive,
                      getPatientOrdersForComparison,
                      compareRowIndex,
                      j
                    )
                      ? 'pointer'
                      : 'default',
                  }}
                >
                  {getCurrentModelAlias({
                    split: j,
                    propsArrLength: propsArr.length,
                    isModelCompareActive,
                    compareRowIndex,
                    translationsForSplitter,
                  })}
                  {shouldRenderChangeButton(
                    isModelCompareActive,
                    getPatientOrdersForComparison,
                    compareRowIndex,
                    j
                  ) && <span className={styles.changeButton}>Change</span>}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Splitter;

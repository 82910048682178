import downloadFile from './download-file/download-file';
import logger from '../logger';
import { getITRModelWithPolling } from './model-fetcher/itr-fetcher.service';
import * as configuration from '../constants/configurationValues.constants';
import { EnvironmentParams, Environments } from './../constants';
import { settingsManager } from '../settings-manager';
import { map } from '../api-service/apiMap';
import { getEnvironmentParams, compileUrl } from '../api-service/apiService-helper';
import { logToTimberBI, biMethods } from '../timberLogger';

const { apiMapKeys } = map;
const modelTypes = {
  ITR: 'itr',
  NIRI: 'niri',
};

const { NIRI_ASSET_REFFERENCE_TYPE_NUMBER } = EnvironmentParams;

const getModelUrl = () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  const apiMapKey = apiMapKeys('getModelByType');
  const { baseUrl, url } = getEnvironmentParams(apiMapKey);
  const modelUrl = compileUrl({
    baseUrl,
    url,
    queryParams: {
      orderId,
      fromGenericApi,
      type: 'itr',
    },
  });
  return modelUrl;
};

const niriQueryParams = (orderId) => {
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  return {
    assetReferenceType: NIRI_ASSET_REFFERENCE_TYPE_NUMBER,
    orderId: orderId || settingsManager.getConfigValue(configuration.orderId),
    fromGenericApi,
    type: modelTypes.NIRI,
  };
};

const getNiriFilePath = (orderId) => {
  const niriFilePath = {
    queryParams: niriQueryParams(orderId),
  };
  return niriFilePath;
};

const getNiriFile = async (progressCB, params, isModelCompare) => {
  try {
    const { orderId } = params.queryParams || params;
    const requestParams = {
      url: `testModels/${orderId}.niri`,
      isModelCompare,
    };

    return await downloadFile({ ...requestParams, fileType: 'niri', options: { credentials: 'include' }, progressCB });
  } catch (error) {
    logger
      .error('No NIRI file path')
      .data({ module: 'eupRequests' })
      .end();

    logToTimberBI(
      biMethods.errorReportBiLog({
        object: 'eupRequests',
        code: 'No NIRI file path',
        description: error.message,
        severity: 'Fatal - No NIRI file path',
      })
    );
  }
};

const getModel = async ({ orderId, isModelCompare = false }) => {
  const requestParams = {
    url: `testModels/${orderId}.itr`,
    isModelCompare,
  };
  return await getITRModelWithPolling(Environments.EUP, requestParams);
};

const getItrModel = async () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  return await getModel({ orderId });
};

const getCompareItrModel = async (orderId) => {
  return await getModel({ orderId, isModelCompare: true });
};

export const testRequests = {
  getNiriFile,
  getItrModel,
  getCompareItrModel,
  getModelUrl,
  getNiriFilePath,
};

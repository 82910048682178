export const scannerMappingKeys = {
  platformBISessionId: 'platformBISessionId',
  sessionId: 'sessionId',
  sessionType: 'sessionType',
  isDemoModeSession: 'isDemoModeSession',
  loggedInUserId: 'userId',
  roleType: 'roleType',
  scannerBaseUnitType: 'BaseUnitType',
  systemType: 'systemType',
  scannerVersion: 'ScannerPackageVersion',
  scannerSerial: 'scannerSerial',
  scannerResGuid: 'scannerResGuid',
  wandSerial: 'wandSerial',
  contactId: 'contactId',
  companyId: 'companyId',
  region: 'region',
  patientId: 'patientGuid',
  orderId: 'orderId',
  rxId: 'rxGuid',
  dateFormat: 'dateFormat',
  language: 'locale',
  accessToken: 'accessToken',
  PlatformServices: 'PlatformServices',
  userId: 'userId',
  SessionManagementRefresh: 'refreshTokenUrl',
  AssetManager: 'assetManagerUrl',
};

export const PREP_PREFIX = 'ada';

export const JAWS_ENUM = {
  upper_jaw: 'upper_jaw',
  lower_jaw: 'lower_jaw',
};

export const TeethNumbersConstants = {
  UPPER_JAW_FIRST_TEETH_NUMBER: 1,
  UPPER_JAW_LAST_TEETH_NUMBER: 16,
  LOWER_JAW_FIRST_TEETH_NUMBER: 17,
  LOWER_JAW_LAST_TEETH_NUMBER: 32,
};

export const EXTRACTED_MODEL = {
  multiBite: {
    isAvailable: false,
    transformationMatrix: {},
    transformationInverseMatrix: {},
    transformAction: 'none',
  },
  objects: {},
};

export const ObjectSuffix = {
  ADJACENT: '_adjacent',
  DITCH: '_ditch',
  COVER: '_cover',
  INNER: '_inner',
  OUTER: '_outer',
  MARGIN_LINE: '_margin_line',
};

const generatePrepsKeys = () => {
  var prepKeys = {};

  for (let adaId = 1; adaId <= 32; adaId++) {
    var prepPrefix = 'ada' + adaId;
    prepKeys[adaId] = {
      Prep: prepPrefix,
      Adjacent: prepPrefix + ObjectSuffix.ADJACENT,
      DitchCover: prepPrefix + ObjectSuffix.DITCH + ObjectSuffix.COVER,
      DitchInner: prepPrefix + ObjectSuffix.DITCH + ObjectSuffix.INNER,
      DitchOuter: prepPrefix + ObjectSuffix.DITCH + ObjectSuffix.OUTER,
      MarginLine: prepPrefix + ObjectSuffix.MARGIN_LINE,
    };
  }
  return prepKeys;
};

export const ObjectsKeys = {
  UPPER_JAW: 'upper_jaw',
  LOWER_JAW: 'lower_jaw',
  UPPER_PRETREATMENT_JAW: 'upper_pretreatment_jaw',
  LOWER_PRETREATMENT_JAW: 'lower_pretreatment_jaw',
  UPPER_DENTURE_COPY_SCAN_JAW: 'upper_denture_copy_scan_jaw',
  LOWER_DENTURE_COPY_SCAN_JAW: 'lower_denture_copy_scan_jaw',
  UPPER_EMERGENCE_PROFILE_JAW: 'upper_emergence_profile_jaw',
  LOWER_EMERGENCE_PROFILE_JAW: 'lower_emergence_profile_jaw',
  Preps: generatePrepsKeys(),
};

export const modelTypes = {
  ORIGIN: 'model',
  COMPARE: 'compare_model',
};

import React, { useMemo } from 'react';

export const MeshWithTextureMapping = (props) => {
  const { map, geometry, textureName, modelName, isLuminaScan } = props;

  const RenderMeshWithTextureMapping = useMemo(() => {
    return textureName.includes('texture_mapping') ? (
      <mesh geometry={geometry} name={modelName}>
        {isLuminaScan ? (
          <phongTextureMaterial
            name="PhongTextureMaterial"
            attach="material"
            texture={map}
            args={[map, props]}
            map={map}
            map-flipY={false}
          />
        ) : (
          <textureMaterial
            name="TextureMaterial"
            attach="material"
            texture={map}
            map={map}
            args={[map, props]}
            map-flipY={false}
          />
        )}
      </mesh>
    ) : null;
  }, [geometry, isLuminaScan, map, modelName, props, textureName]);

  return RenderMeshWithTextureMapping;
};

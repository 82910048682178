import React, { Fragment, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { logToTimberBI, biMethods, utils, storeCommunicationService } from '@web-3d-tool/shared-logic';
import { get, set } from 'lodash';
import ImageFrameManipulation from '../ImageFrameManipulation';
import ImageFrameManipulation2DRotation from '../ImageFrameManipulation/ImageFrameManipulation2DRotation/ImageFrameManipulation2DRotation';
import DrawerPanel from '../ImageFrameManipulation/Drawers/drawerPanel/drawerPanel';
import EnlargeFrameButton from './enlagreFrameButton';
import EmptyPanel from './emptyPanel';
import stylesNo360 from './ImageFramesContainer.module.css';
import styles360 from './ImageFramesContainer360.module.css';

const ImageFramesContainer = ({
  width,
  height,
  sourcesArr,
  enlargeWidth,
  enlargeHeight,
  showEnlargeButton,
  className: classNameProp,
  isDebugEnabled,
  verticalSpace,
  is360,
  isActive,
  isLoading,
  isReady,
  imageFrameContainerTop,
  locationParentObject,
  luminaOriginalImageSize,
  elementOriginalImageSize,
  viewer360Align2DImages,
  setIsEnlargedFrame,
  isEnlargedFrame,
  shouldMoveComparePaneltoLeft,
  isModelCompared,
  setEmptyPanelRef,
  modelType,
  isAOHS,
  closestPhotoObjectParams,
}) => {
  // params
  const isAlign2DImages = is360 && viewer360Align2DImages;
  const DRAWER_WIDTH = isAlign2DImages ? 60 : 38;
  const SLIDER_SIZE = isAlign2DImages ? 0 : 32;
  const MARGIN_FRAMES = is360 ? 8 : 0;
  const styles = is360 ? styles360 : stylesNo360;
  const className = classNames(classNameProp, isAlign2DImages ? styles.containerRotation : styles.container);

  // states
  const [containerSize, setContainerSize] = useState(null);
  const [frameSize, setFrameSize] = useState(null);
  const [isDrawerDisabled, setIsDrawerDisabled] = useState(true);
  const [imageProps, setImageProps] = useState({ left: 0, top: 0, scale: 1 });
  const [slidersValues, setslidersValues] = useState({});
  const [enlargedImage, setEnlargedImage] = useState(null);

  const shouldShowDrawer = useMemo(() => !sourcesArr.some((source) => source.isPlaceHolderIcon), [sourcesArr]);
  const { scale, top, left } = imageProps;

  // effects
  useEffect(() => {
    const position = isAlign2DImages
      ? {
          bottom: 0,
          top: imageFrameContainerTop,
          right: shouldMoveComparePaneltoLeft ? `${MARGIN_FRAMES + window.innerWidth / 2}px` : `${MARGIN_FRAMES}px`,
        }
      : { top: imageFrameContainerTop };
    if (enlargedImage) {
      setContainerSize({ width: enlargeWidth, height: enlargeHeight, ...position });
    } else {
      setContainerSize({ width, height, ...position });
    }
  }, [
    height,
    enlargedImage,
    width,
    enlargeWidth,
    enlargeHeight,
    isAlign2DImages,
    imageFrameContainerTop,
    shouldMoveComparePaneltoLeft,
    MARGIN_FRAMES,
  ]);

  useEffect(() => {
    const calcFrameSize = (containerWidth, containerHeight, length) => {
      const numberOfFrames = enlargedImage ? 1 : length;
      const margin = verticalSpace / numberOfFrames;
      const SLIDER_WIDTH = DRAWER_WIDTH + SLIDER_SIZE; //The size of the slider (38px) and slide (32px)

      const frameWidth = containerWidth - SLIDER_WIDTH;
      let frameHeight = (() => {
        const is360hub = utils.getIs360HubEnabled();

        let height = containerHeight;
        if (is360hub && viewer360Align2DImages && enlargedImage && sourcesArr.length === 1) {
          height = containerHeight * 2;
        }
        return numberOfFrames === 1 ? height : (height - margin) / numberOfFrames;
      })();
      if (!isFinite(frameHeight)) {
        frameHeight = height;
      }
      return { width: frameWidth, height: frameHeight };
    };

    const { width = 0, height = 0 } = containerSize || {};
    const calculatedFrameSize = calcFrameSize(width, height, sourcesArr.length);
    setFrameSize(calculatedFrameSize);

    setIsDrawerDisabled(sourcesArr.length === 0);
  }, [
    DRAWER_WIDTH,
    SLIDER_SIZE,
    containerSize,
    enlargeHeight,
    enlargedImage,
    sourcesArr.length,
    verticalSpace,
    viewer360Align2DImages,
  ]);

  useEffect(() => {
    if (shouldShowDrawer) {
      if (enlargedImage) {
        setEnlargedImage((currentType) => {
          if (!currentType) return null;

          const isExistsInArray = sourcesArr.some((item) => item.type === currentType);
          return isExistsInArray ? currentType : null;
        });
      }
    } else {
      setEnlargedImage(null);
    }
  }, [enlargedImage, shouldShowDrawer, sourcesArr]);

  // functions
  const toggleEnlarge = (type, isAlign2DImages) => {
    setEnlargedImage((currentType) => {
      const isEnlarged = currentType === type ? null : type;
      isAlign2DImages && storeCommunicationService.updateStore({ isEnlarged: !!isEnlarged });
      isEnlargedFrame[type] = !!isEnlarged;
      setIsEnlargedFrame(isEnlargedFrame);
      return isEnlarged;
    });
    setImageProps({ scale: 1, top: 0, left: 0 });
    const currentPluginState = type === 'niri' ? 'enlarge NIRI Image' : 'enlarge IOC Image';
    const selectedValue = !enlargedImage ? 'turn on' : 'turn off';

    logToTimberBI(
      biMethods.userActionBiLog({
        objectName: 'Magnifier button',
        action: 'click',
        objectType: 'button',
        locationParentObject,
        selectedValue: 'Magnifier button for ' + currentPluginState + ' is clicked to ' + selectedValue,
        automationId: '',
      })
    );
  };

  const handleChange = ({ scale, top, left }) => {
    setImageProps({ scale, top, left });
  };

  return (
    <>
      {isActive && !isLoading && containerSize && frameSize && (
        <div
          style={
            isAlign2DImages
              ? { ...containerSize, width: 'auto' }
              : sourcesArr.length === 0
              ? containerSize
              : { width: containerSize.width, height: 'auto' }
          }
          className={className}
        >
          {isAlign2DImages ? (
            <ImageFrameManipulation2DRotation
              is360={is360}
              width={frameSize.width}
              height={frameSize.height}
              closestPhotoObjectParams={closestPhotoObjectParams}
              top={top}
              left={left}
              scale={scale}
              onChange={handleChange}
              sourcesArr={sourcesArr}
              isEnlargedFrame={enlargedImage}
              luminaOriginalImageSize={luminaOriginalImageSize}
              elementOriginalImageSize={elementOriginalImageSize}
              isDrawerDisabled={isDrawerDisabled}
              setslidersValues={setslidersValues}
              frameSize={frameSize}
              drawerWidth={DRAWER_WIDTH}
              isReady={isReady}
              toggleEnlarge={toggleEnlarge}
              isDebugEnabled={isDebugEnabled}
              setEmptyPanelRef={setEmptyPanelRef}
              isModelCompared={isModelCompared}
              modelType={modelType}
              containerSize={containerSize}
              isAOHS={isAOHS}
            />
          ) : (
            <>
              {shouldShowDrawer && (
                <DrawerPanel
                  is360={is360}
                  sourcesArr={sourcesArr}
                  isEnlargedFrame={enlargedImage}
                  isDrawerDisabled={isDrawerDisabled}
                />
              )}
              <div
                className={classNames(
                  styles.main,
                  shouldShowDrawer ? styles.imagesContainer : styles.placeholdersContainer
                )}
              >
                {sourcesArr.map(({ src, type, url, isPlaceHolderIcon }, index) => (
                  <Fragment key={index}>
                    {(enlargedImage === null || enlargedImage === type) && (
                      <>
                        {!isPlaceHolderIcon ? (
                          <div className={classNames(styles.enlarge)}>
                            {isDebugEnabled && <div className={styles.imgName}>{url}</div>}
                            {!!showEnlargeButton && (
                              <EnlargeFrameButton
                                is360={is360}
                                type={type}
                                index={index}
                                enlargedImage={enlargedImage}
                                handleEnlarge={toggleEnlarge}
                              />
                            )}
                            <ImageFrameManipulation
                              is360={is360}
                              width={frameSize.width}
                              height={frameSize.height}
                              src={src}
                              closestPhotoObjectParams={closestPhotoObjectParams}
                              onChange={handleChange}
                              top={top}
                              left={left}
                              scale={scale}
                              key={`${type}-image-container`}
                              data-test-id={`${type}-image-container`}
                              id={`${type}-image`}
                              dataBiType={type}
                              type={type}
                              luminaOriginalImageSize={luminaOriginalImageSize}
                              elementOriginalImageSize={elementOriginalImageSize}
                              onBrightnessChanged={(val) =>
                                setslidersValues((slidersValues) => set(slidersValues, `${type}-image.brightness`, val))
                              }
                              onContrastChanged={(val) => {
                                setslidersValues((slidersValues) => set(slidersValues, `${type}-image.contrast`, val));
                              }}
                              defaultBrightness={get(slidersValues, `${type}-image.brightness`)}
                              defaultContrast={get(slidersValues, `${type}-image.contrast`)}
                              isEnlargedFrame={enlargedImage}
                            />
                          </div>
                        ) : (
                          <EmptyPanel
                            type={type}
                            src={src}
                            is360={is360}
                            isReady={isReady}
                            frameSize={frameSize}
                            drawerWidth={DRAWER_WIDTH}
                            setEmptyPanelRef={setEmptyPanelRef}
                            index={index}
                          />
                        )}
                      </>
                    )}
                  </Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
ImageFramesContainer.defaultProps = {
  width: 360,
  height: 720,
  enlargeWidth: 500,
  enlargeHeight: 720,
  showEnlargeButton: true,
  is360: false,
  rotation: 0,
  shouldTransform: true,
  verticalSpace: 10,
  originalImageSize: { width: 960, height: 540 },
};

ImageFramesContainer.propTypes = {
  /**
   * The conatiner width
   */
  width: PropTypes.number,
  /**
   * The conatiner height
   */
  height: PropTypes.number,
  /**
   * Images sourcesArr
   */
  sourcesArr: PropTypes.array.isRequired,
  /**
   * The conatiner width when enlarged
   */
  enlargeWidth: PropTypes.number,
  /**
   * The conatiner height when enlarged
   */
  enlargeHeight: PropTypes.number,
  /**
   * Enlarged button show/hide
   */
  showEnlargeButton: PropTypes.bool,
  /**
   * The rotation angle to present the images
   */
  rotation: PropTypes.number,
  /**
   * Should Transform rotate the images in 360 mode according to specific angle
   */
  shouldTransform: PropTypes.bool,
  /**
   * The verticalSpace between images
   */
  verticalSpace: PropTypes.number,
  /**
   * having 360 behaviour
   */
  is360: PropTypes.bool,
  /**
   * original image size from zip
   */
  originalImageSize: PropTypes.object,
};

export default ImageFramesContainer;
